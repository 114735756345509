import { useTranslation } from "react-i18next";

const TailwindToggle = ({ value, setValue, isMobile }) => {
  const { t } = useTranslation("common");

  return isMobile ? (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex h-full min-h-[3rem] w-full sm:max-w-[350px] items-center justify-center gap-x-4 rounded-full border bg-[#ECECF6] p-[2px]"
    >
      <div
        className={`flex w-full cursor-pointer items-center justify-center rounded-full text-center text-black ${
          value ? "border-2 border-[#12A3BA] bg-white" : ""
        }`}
        onClick={() => setValue(true)}
      >
        <p className="m-0 w-full py-2.5 font-medium">{t("main.search_flexible")}</p>
      </div>
      <div
        className={`w-full cursor-pointer text-center text-black ${
          value ? "" : "border-2 border-[#12A3BA] bg-white"
        } flex items-center justify-center rounded-full px-4`}
        onClick={() => setValue(false)}
      >
        <p className="m-0 w-full py-2.5 font-medium">{t("main.search_date")}</p>
      </div>
    </div>
  ) : (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex h-full min-h-[3rem] w-[334px] min-w-[11rem] items-center justify-center gap-x-4 rounded-full border bg-[#EBF2FF] py-1.5 px-1.5"
    >
      <div
        className={`flex w-full cursor-pointer items-center justify-center rounded-full text-center ${
          value ? "bg-[#12A3BA] text-[#FFFFFF]" : "bg-[#EBF2FF]"
        }`}
        onClick={() => setValue(true)}
      >
        <p className="m-0 w-full py-2.5">{t("main.search_flexible")}</p>
      </div>
      <div
        className={`w-full cursor-pointer text-center ${
          value ? "bg-[#EBF2FF]" : "bg-[#12A3BA] text-[#FFFFFF]"
        } flex items-center justify-center rounded-full px-4`}
        onClick={() => setValue(false)}
      >
        <p className="m-0 w-full py-2.5">{t("main.search_date")}</p>
      </div>
    </div>
  );
};

export default TailwindToggle;
