import request from "@libs/request/Api";
import TailwindToggle from "@components/search/TailwindToggle";
import { SearchIcon } from "@components/search/ComponentIcon";
import DateHelper from "@helper/DateHelper";
import { useTranslation } from "react-i18next";
import { Image, Skeleton, DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import { IoCloseCircleSharp } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { useState, useEffect, useMemo, useRef } from "react";
import { motion } from "framer-motion";
import { FiCheck } from "react-icons/fi";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import deLocale from "antd/lib/locale-provider/de_DE";
import locale from "antd/lib/locale-provider/en_GB";

const { RangePicker } = DatePicker;

const DesktopSearchBar = ({ type }) => {
  // Sport related:
  const [openSport, setOpenSport] = useState(false);
  const [sportValue, setSportValue] = useState();
  const [activeSportKeyIndex, setActiveSportKeyIndex] = useState(0);
  const [isSearchActive, setIsSearchActive] = useState(false);

  // Destination related:
  const [openDest, setOpenDest] = useState(false);
  const [destValue, setDestValue] = useState();
  const [destinations, setDestinations] = useState();
  const [isDestLoading, setIsDestLoading] = useState(false);
  const [destInputValue, setDestInputValue] = useState("");
  const [activeDestKeyIndex, setActiveDestKeyIndex] = useState(0);
  const [allDests, setAllDests] = useState([]);
  const [hasSearchedDest, setHasSearchedDest] = useState(false);

  // Date box related:
  const [openDateBox, setOpenDateBox] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [calendarData, setCalendarData] = useState();
  const [isFlexibale, setIsFlexibale] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [isMonthSelected, setIsMonthSelected] = useState(false);

  const { t, i18n } = useTranslation("common");
  const router = useRouter();
  const destItemsRefs = useRef([]);
  const sportItemsRefs = useRef([]);
  const destInputRef = useRef(null);

  // specific calendar data
  const isFullAccess = calendarData?.specific?.full_access_calender;
  const workDays = calendarData?.specific?.work_dates || [];
  const excludeDates = calendarData?.specific?.exclude_dates || [];
  const validDates = calendarData?.specific?.valid_dates || [];
  const now = moment();
  const isEn = i18n.language === "en";
  const dateFormat = isEn ? "DD/MM/YYYY" : "DD.MM.YYYY";

  const sports = [
    {
      id: "0",
      title: t("frontpage.all_sports"),
      alias: "0",
      type: "sport",
    },
    {
      id: "3",
      title: t("search.searchbar_sports_option_1"),
      alias: "surf",
      type: "sport",
    },
    {
      id: "2",
      title: t("search.searchbar_sports_option_2"),
      alias: "yoga",
      type: "sport",
    },
    {
      id: "5",
      title: t("search.searchbar_sports_option_3"),
      alias: "fitness",
      type: "sport",
    },
    {
      id: "4",
      title: t("search.searchbar_sports_option_4"),
      alias: "hiking",
      type: "sport",
    },
    {
      id: "210",
      title: t("search.searchbar_sports_option_5"),
      alias: "klettern-bouldern-2",
      type: "sport",
    },
    {
      id: "222",
      title: t("search.searchbar_sports_option_6"),
      alias: "bunter-aktivmix",
      type: "sport",
    },
    {
      id: "6",
      title: t("search.searchbar_sports_option_7"),
      alias: "martial-arts",
      type: "sport",
    },
    {
      id: "219",
      title: t("search.searchbar_sports_option_8"),
      alias: "kajak-kanu",
      type: "sport",
    },
    {
      id: "211",
      title: t("search.searchbar_sports_option_9"),
      alias: "fahrrad-mountainbike",
      type: "sport",
    },
    {
      id: "231",
      title: t("search.searchbar_sports_option_10"),
      alias: "kitesurfen",
      type: "sport",
    },
    {
      id: "194",
      title: t("search.searchbar_sports_option_11"),
      alias: "winter-sports",
      type: "sport",
    },
  ];

  useEffect(() => {
    if (openSport || openDest || openDateBox) {
      setIsSearchActive(true);
    } else {
      setIsSearchActive(false);
    }
  }, [openSport, openDest, openDateBox]);

  useEffect(() => {
    if (hasSearchedDest && destinations?.length > 0) {
      setAllDests([
        { type: "all", title: t("frontpage.all_des") },
        ...destinations,
      ]);
    } else {
      setAllDests([
        { type: "all", title: t("frontpage.all_des") },
        ...(destinations?.continents?.map((des) => ({
          ...des,
          type: "continent",
        })) || []),
        ...(destinations?.countries?.map((des) => ({
          ...des,
          type: "country",
        })) || []),
        ...(destinations?.states?.map((des) => ({ ...des, type: "state" })) ||
          []),
      ]);
    }

    setActiveDestKeyIndex(0);
  }, [destinations]);

  // Scroll the focused item into view
  useEffect(() => {
    if (
      openSport &&
      !openDest &&
      activeSportKeyIndex >= 0 &&
      sportItemsRefs.current[activeSportKeyIndex]
    ) {
      sportItemsRefs.current[activeSportKeyIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else if (
      openDest &&
      !openSport &&
      activeDestKeyIndex >= 0 &&
      destItemsRefs.current[activeDestKeyIndex]
    ) {
      destItemsRefs.current[activeDestKeyIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeDestKeyIndex, activeSportKeyIndex]);

  useEffect(() => {
    if (openDest && destInputRef.current) {
      destInputRef.current.focus();
    }
  }, [openDest]);

  const handleKeyPress = (e) => {
    if (openSport && !openDest) {
      // sport
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveSportKeyIndex((prev) => (prev + 1) % sports.length);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveSportKeyIndex(
          (prev) => (prev - 1 + sports.length) % sports.length
        );
      } else if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        if (activeSportKeyIndex >= 0 && activeSportKeyIndex < sports.length) {
          const selectedSport = sports[activeSportKeyIndex];
          handleSportSelection(selectedSport);
        }
      }
    } else if (openDest && !openSport) {
      // dest
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveDestKeyIndex((prev) => (prev + 1) % allDests.length);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveDestKeyIndex(
          (prev) => (prev - 1 + allDests.length) % allDests.length
        );
      } else if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();

        if (activeDestKeyIndex >= 0 && activeDestKeyIndex < allDests.length) {
          const selectedItem = allDests[activeDestKeyIndex];

          if (selectedItem?.type === "all") {
            handleDestSelection("all", "country");
          } else {
            handleDestSelection(selectedItem, selectedItem.type);
          }
        }
      } else {
        destInputRef.current && destInputRef.current.focus();
      }
    }
  };

  const getDateData = async (selectedDest) => {
    setIsCalendarLoading(true);
    setOpenDest(false);
    setOpenSport(false);
    setOpenDateBox(true);

    try {
      const sportParams = handlePostParams(sportValue, "sport");
      const destParams = handlePostParams(selectedDest, "dest");

      const res = await request.get(
        `/search/v2/travel-date${sportParams ? `?${sportParams}` : ""}${
          destParams ? `${sportParams ? "&" : "?"}${destParams}` : ""
        }`
      );

      setCalendarData(res?.data);
    } catch (err) {
      console.log(err.response?.data || err.message);
    } finally {
      setIsCalendarLoading(false);
      setOpenDest(false);
      setOpenSport(false);
      setOpenDateBox(true);
    }
  };

  const handlePostParams = (selectedFilter, type) => {
    if (!selectedFilter?.alias) return;

    const queryParams = new URLSearchParams();

    if (type === "sport") {
      queryParams.set("sport", selectedFilter.alias);
    } else {
      queryParams.set("destination_alias", selectedFilter.alias);
    }

    return queryParams.toString();
  };

  // Get dests after sport selection
  const handleSportSelection = async (selectedSport) => {
    if (selectedSport) {
      setDestValue();
      setDestInputValue("");
      setSportValue(selectedSport);
      setSelectedMonth([]);
      setStartDate(null);
      setEndDate(null);
      setIsDateSelected(false);
      setIsMonthSelected(false);
    }

    setOpenSport(false);
    setOpenDest(true);
    setIsDestLoading(true);

    const params = handlePostParams(selectedSport, "sport");

    try {
      const res = await request.get(
        `search/v2/destination${params ? `?${params}` : ""}`
      );
      setDestinations(res?.data);
      setHasSearchedDest(false);
    } catch (err) {
      console.log(err.response?.data || err.message);
    } finally {
      setIsDestLoading(false);
      setOpenDest(true);
      setOpenSport(false);
      destInputRef.current && destInputRef.current.focus();
    }
  };

  // get dates after dest selection
  const handleDestSelection = async (selectedDest, type) => {
    if (!selectedDest) return;

    const isAll = selectedDest === "all";
    const destId = isAll ? "0" : selectedDest?.id || null;
    const title = isAll
      ? t("frontpage.all_des")
      : selectedDest?.alias === "galapagos-inseln"
      ? isEn
        ? "Galapagos Islands"
        : "Galapagos Inseln"
      : selectedDest?.title?.en || selectedDest?.title?.de
      ? isEn
        ? selectedDest?.title?.en ?? selectedDest?.title?.de
        : selectedDest?.title?.de ?? selectedDest?.title?.en
      : selectedDest?.title;

    const temp = {
      alias: isAll ? "0" : selectedDest?.alias,
      id: destId,
      title: title,
      type: type,
      displayTitle: isAll
        ? "all"
        : selectedDest?.alias === "galapagos-inseln"
        ? {
            en: "Galapagos Islands",
            de: "Galapagos Inseln",
          }
        : selectedDest?.title,
    };

    setHasSearchedDest(false);
    setDestValue(temp);
    setDestInputValue(title);
    getDateData(temp);

    // clear dates
    setSelectedMonth([]);
    setStartDate(null);
    setEndDate(null);
    setIsDateSelected(false);
    setIsMonthSelected(false);
  };

  // Memoize Debounced destinations search
  const debouncedSearch = useMemo(
    () =>
      debounce(async (value, params) => {
        const trimmedValue = value.trim();

        setIsDestLoading(true);
        const queryParams = `?search=${encodeURIComponent(trimmedValue)}${
          params ? `&${params}` : ""
        }`;

        try {
          const result = await request.get(
            `search/v2/destination${queryParams}`
          );
          const data = result?.data;

          setDestinations(data || {});
          setHasSearchedDest(
            !data?.continents || !data?.countries || !data?.states
          );
        } catch (error) {
          console.log("Error:", error.response || error.message);
        } finally {
          setIsDestLoading(false);
        }
      }, 500), // 500ms delay
    [sportValue]
  );

  // Get destinations after type-in search
  const handleDestTypeInSearch = (value) => {
    setIsDestLoading(true);
    setOpenDest(true);
    setDestInputValue(value);
    setHasSearchedDest(value && value !== "");

    if (!value && value === "") {
      setDestInputValue(" ");
    }
    const params = handlePostParams(sportValue, "sport");

    // Call API with 500ms delay
    debouncedSearch(value, params);
  };

  // disabled dates in specific calendar
  const handleDisabledDates = (current) => {
    if (!current) return;

    // Disable past dates - before today
    if (current.isBefore(moment(now).endOf("day"))) return true;

    // Disable all days except workdays (allow only workdays)
    if (workDays?.length > 0) {
      return !workDays.some((date) => date - 1 == moment(current).format("d"));
    }

    // Full access calendar logic
    if (isFullAccess && excludeDates) {
      // Disable excluded dates
      return excludeDates?.includes(moment(current).format("YYYY-MM-DD"));
    }

    // Restricted calendar logic: only allow validDates
    if (validDates?.length > 0) {
      return !validDates?.includes(moment(current).format("YYYY-MM-DD"));
    }

    // Allow all dates if no restrictions
    return false;
  };

  // Handle search btn click
  const applyFilters = (e) => {
    e.stopPropagation();
    // close all tabs
    setOpenSport(false);
    setOpenDest(false);
    setOpenDateBox(false);

    // build query params - URL encoding
    const buildQueryParam = (key, value) =>
      `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

    // build date range query params
    const buildDateRangeParam = (start, end) => {
      const formattedStart = moment(start).format("YYYY-MM-DD");
      const formattedEnd = moment(end).format("YYYY-MM-DD");
      return `from=${formattedStart}&to=${formattedEnd}`;
    };

    // handle month array and convert it to query string
    const buildMonthParam = (months) =>
      months?.map((month) => buildQueryParam("month", month)).join("&");

    const getDestType = (type) =>
      type === "continent"
        ? "continent"
        : type === "country"
        ? "country"
        : type === "state"
        ? "state"
        : "country";

    const queryParams = [];

    // Sport Filter
    if (sportValue?.alias) {
      if (sportValue?.alias === "0") {
        queryParams.push(buildQueryParam("all_sports", true));
      } else {
        queryParams.push(buildQueryParam("sport", sportValue?.alias));
      }
    }

    // destination Filter
    if (destValue?.alias) {
      if (destValue?.alias === "0") {
        queryParams.push(buildQueryParam("all_destinations", true));
      } else {
        const countryKey = getDestType(destValue?.type);

        // Handle URL encoding for countries with "&" in their names
        const encodedCountry = destValue?.alias?.includes("&")
          ? destValue?.alias.replace("&", "%26")
          : destValue?.alias;

        queryParams.push(buildQueryParam(countryKey, encodedCountry));
        localStorage.setItem("destinationFilter", JSON.stringify(destValue));
      }
    }

    // Date Range Filter - speecific dates
    if (startDate && endDate) {
      queryParams.push(buildDateRangeParam(startDate, endDate));
    }

    // Month Filter - flexibile dates
    if (selectedMonth?.length > 0 && Array.isArray(selectedMonth)) {
      queryParams.push(buildMonthParam(selectedMonth));
    }

    // Filter out any empty params before joining with "&"
    const finalQueryString = queryParams.filter(Boolean).join("&");

    // Update URL
    if (queryParams?.length > 0 && finalQueryString) {
      if (typeof window !== undefined) {
        window.location.href = `/s/moverii?page=1&${finalQueryString}`;
      } else {
        router.push(`/s/moverii?page=1&${finalQueryString}`);
      }
    } else {
      if (typeof window !== undefined) {
        window.location.href = "/s/moverii";
      } else {
        router.push("/s/moverii");
      }
    }

    // clear values after URL update
    setSportValue();
    setDestValue();
    setDestInputValue("");
    setStartDate(null);
    setEndDate(null);
    setSelectedMonth([]);
    setHasSearchedDest(false);
  };

  return (
    <div className="w-full">
      <motion.div
        className={`w-full flex flex-col items-center justify-center container relative ${
          type === "home" ? "mt-[120px] !z-[100]" : ""
        }`}
        onKeyDown={handleKeyPress}
        initial={type === "header" ? { y: "-100%" } : {}}
        animate={
          type === "home"
            ? isSearchActive
              ? { y: "-365px" }
              : {}
            : { y: "50px" }
        }
        transition={{
          duration: 0.1,
          type: "spring",
          stiffness: 300,
          damping: 50,
        }}
      >
        <div className="w-full lg:w-[912px] h-[75px] rounded-full bg-gray-100 !z-[100] py-2 px-4 md:pl-8 pr-20 flex items-center relative shadow-sm">
          {/* cols */}
          <div className="w-full h-full grid grid-cols-3">
            {/* sports */}
            <div
              tabIndex="0"
              className="border-r-2 flex flex-col justify-center gap-0 cursor-pointer relative pr-3 !outline-none"
              onClick={() => {
                setOpenSport((prev) => !prev);
                setOpenDest(false);
                setOpenDateBox(false);
                setActiveSportKeyIndex(0);
              }}
            >
              <p className="font-medium text-base">
                {t("frontpage.sport_select")}
              </p>
              <div className="w-full flex items-center justify-between gap-3">
                {sportValue?.title ? (
                  <div className="w-full flex items-center justify-between gap-1">
                    <p className="text-black text-base font-medium line-clamp-1">
                      {sportValue?.title}
                    </p>
                    <div>
                      <IoCloseCircleSharp
                        className="text-gray-70 hover:text-gray-500 text-base"
                        onClick={() => {
                          setOpenSport(true);
                          setSportValue();
                          setOpenDest(false);
                          setOpenDateBox(false);
                          setDestValue();
                          setDestInputValue("");
                          setIsDateSelected(false);
                          setIsMonthSelected(false);
                          setSelectedMonth([]);
                          setStartDate(null);
                          setEndDate(null);
                          setIsFlexibale(true);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex items-center justify-between gap-1">
                    <p className="text-[#9ca3af] text-base">
                      {t("frontpage.sport_select_ph")}
                    </p>
                    <div>
                      <IoIosArrowDown
                        className={`text-orange text-base transition-all duration-500 ${
                          openSport ? "rotate-180" : ""
                        }`}
                      />
                    </div>
                  </div>
                )}
              </div>

              {openSport && (
                <motion.div
                  className={`w-full absolute top-[75px] bottom-0 left-0 right-0 h-[400px] bg-white rounded-2xl shadow-xl ${
                    type === "home" ? "!z-[100]" : "!z-[120]"
                  }`}
                  initial={{ opacity: 0.1, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.1,
                    type: "spring",
                    stiffness: 300,
                    damping: 50,
                  }}
                >
                  <ul className="w-full h-full bg-white relative rounded-2xl py-1 flex flex-col gap-[1px] overflow-y-scroll scroll">
                    {sports?.map((sport, i) => (
                      <li
                        key={sport.alias + sport.id}
                        onClick={() => handleSportSelection(sport)}
                        className={`w-full flex items-center justify-between py-2 hover:bg-gray-20 px-3 transition-all duration-200 hover:pl-4 ${
                          sportValue?.alias === sport.alias ? "bg-gray-20" : ""
                        } ${
                          activeSportKeyIndex === i ? "bg-gray-20 pl-4" : ""
                        }`}
                        ref={(el) => (sportItemsRefs.current[i] = el)}
                      >
                        <p className="text-base font-medium">{sport.title}</p>

                        {sportValue?.alias === sport.alias && (
                          <FiCheck className="text-orange text-xl" />
                        )}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </div>

            {/* Destinations */}
            <div
              tabIndex="0"
              className="border-r-2 flex flex-col justify-center px-3 gap-0 cursor-pointer relative !outline-none"
              onClick={(e) => {
                e.preventDefault();
                setOpenDest((prev) => !prev);
                setOpenSport(false);
                setOpenDateBox(false);
                setActiveDestKeyIndex(0);
                setHasSearchedDest(false);

                if (!sportValue && !openDest) {
                  handleSportSelection(sportValue);
                }
              }}
            >
              <p className="font-medium text-base">
                {t("frontpage.destination_select")}
              </p>
              <div className="w-full flex items-center gap-3">
                <input
                  value={destInputValue}
                  ref={destInputRef}
                  type="text"
                  className="w-full !bg-transparent text-base h-[22px] text-black font-medium placeholder:text-[#9ca3af] placeholder:font-normal"
                  placeholder={t("frontpage.destination_select_ph")}
                  onChange={(e) => handleDestTypeInSearch(e.target.value)}
                  onClick={() =>
                    destInputRef.current && destInputRef.current.focus()
                  }
                />
                {destValue ? (
                  <div>
                    <IoCloseCircleSharp
                      className="text-gray-70 hover:text-gray-500 text-base"
                      onClick={() => {
                        setDestValue();
                        setDestInputValue("");
                        setIsDateSelected(false);
                        setIsMonthSelected(false);
                        setSelectedMonth([]);
                        setStartDate(null);
                        setEndDate(null);
                        setIsFlexibale(true);
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <IoIosArrowDown
                      className={`text-orange text-base transition-all duration-500 ${
                        openDest ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                )}
              </div>

              {openDest && (
                <motion.div
                  className={`w-full absolute top-[75px] bottom-0 left-0 right-0 h-[400px] bg-white rounded-2xl shadow-xl ${
                    type === "home" ? "!z-[100]" : "!z-[120]"
                  }`}
                  initial={{ opacity: 0.1, y: -100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.1,
                    type: "spring",
                    stiffness: 300,
                    damping: 50,
                  }}
                >
                  {isDestLoading ? (
                    <div className="w-full h-full bg-white relative rounded-2xl py-1 flex flex-col gap-y-3 overflow-hidden">
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                        <Skeleton.Button
                          key={"destination-loading-" + item}
                          active
                          className="!w-full !h-[35px]"
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="w-full h-full">
                      {!hasSearchedDest &&
                      destinations?.continents?.length === 0 &&
                      destinations?.countries?.length === 0 &&
                      destinations?.states?.length === 0 ? (
                        // No destination available
                        <motion.div
                          className="w-full h-full flex flex-col items-center justify-center"
                          initial={{ opacity: 0.1, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{
                            duration: 0.1,
                            type: "spring",
                            stiffness: 300,
                            damping: 50,
                          }}
                        >
                          <Image
                            src="/icon/search/no-data.png"
                            alt="no data!"
                            preview={false}
                            width={"200px"}
                            height={"200px"}
                            loading="lazy"
                          />
                          <p className="max-w-full text-center text-wrap !text-black px-2">
                            {t("search.searchbar_no_data_text_dest")}
                          </p>
                        </motion.div>
                      ) : (
                        // results
                        <ul
                          className="w-full h-full bg-white relative rounded-2xl py-1 overflow-y-scroll scroll flex flex-col gap-[1px]"
                          tabIndex={0}
                        >
                          {/* All destinations */}
                          <li
                            className={`text-base font-medium py-2 hover:bg-gray-20 px-3 transition-all duration-200 hover:pl-4 ${
                              activeDestKeyIndex === 0 ? "bg-gray-20 pl-4" : ""
                            }`}
                            onClick={() =>
                              handleDestSelection("all", "country")
                            }
                            data-index={0}
                            ref={(el) => (destItemsRefs.current[0] = el)}
                          >
                            {t("frontpage.all_des")}
                          </li>

                          {hasSearchedDest ? (
                            <div className="flex flex-col gap-[1px] w-full">
                              {destinations?.length > 0 ? (
                                destinations?.map((des, i) => (
                                  <li
                                    key={des?.alias + i}
                                    data-index={i + 1}
                                    ref={(el) =>
                                      (destItemsRefs.current[i + 1] = el)
                                    }
                                    className={`text-base font-medium py-2 hover:bg-gray-20 px-3 transition-all duration-200 hover:pl-4 ${
                                      activeDestKeyIndex === i + 1
                                        ? "bg-gray-20 pl-4"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleDestSelection(des, des?.type)
                                    }
                                  >
                                    {des?.alias === "galapagos-inseln"
                                      ? isEn
                                        ? "Galapagos Islands"
                                        : "Galapagos Inseln"
                                      : des?.title?.en || des?.title?.de
                                      ? isEn
                                        ? des?.title?.en ?? des?.title?.de
                                        : des?.title?.de ?? des?.title?.en
                                      : des?.title}
                                  </li>
                                ))
                              ) : (
                                <motion.div
                                  className="w-full h-full flex flex-col items-center justify-center"
                                  initial={{ opacity: 0.1, scale: 0.5 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{
                                    duration: 0.1,
                                    type: "spring",
                                    stiffness: 300,
                                    damping: 50,
                                  }}
                                >
                                  <Image
                                    src="/icon/search/no-data.png"
                                    alt="no data!"
                                    preview={false}
                                    width={"200px"}
                                    height={"200px"}
                                    loading="lazy"
                                  />
                                  <p className="max-w-full text-center text-wrap !text-black px-2">
                                    {t("search.searchbar_no_data_text_dest")}
                                  </p>
                                </motion.div>
                              )}
                            </div>
                          ) : (
                            <div className="flex flex-col gap-[1px] w-full">
                              {/* continent title */}
                              {destinations?.continents?.length > 0 && (
                                <li className="text-base font-normal py-2 px-3 text-[#9ca3af]">
                                  {t("searchbar_category_continents")}
                                </li>
                              )}

                              {/* continent results */}
                              {destinations?.continents?.length > 0 &&
                                destinations?.continents?.map((des, i) => (
                                  <li
                                    key={des?.alias + i}
                                    data-index={i + 1}
                                    ref={(el) =>
                                      (destItemsRefs.current[i + 1] = el)
                                    }
                                    className={`text-base font-medium py-2 hover:bg-gray-20 px-3 transition-all duration-200 hover:pl-4 ${
                                      activeDestKeyIndex === i + 1
                                        ? "bg-gray-20 pl-4"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleDestSelection(des, "continent")
                                    }
                                  >
                                    {des?.title?.en || des?.title?.de
                                      ? isEn
                                        ? des?.title?.en ?? des?.title?.de
                                        : des?.title?.de ?? des?.title?.en
                                      : des?.title}
                                  </li>
                                ))}

                              {/* countries title */}
                              {destinations?.countries?.length > 0 && (
                                <li className="text-base font-normal py-2 px-3 text-[#9ca3af]">
                                  {t("searchbar_category_countries")}
                                </li>
                              )}

                              {/* countries results */}
                              {destinations?.countries?.length > 0 &&
                                destinations?.countries?.map((des, i) => (
                                  <li
                                    key={des?.alias + i}
                                    data-index={
                                      i +
                                      1 +
                                      (destinations?.continents?.length || 0)
                                    }
                                    ref={(el) =>
                                      (destItemsRefs.current[
                                        i +
                                          1 +
                                          (destinations?.continents?.length ||
                                            0)
                                      ] = el)
                                    }
                                    className={`text-base font-medium py-2 hover:bg-gray-20 px-3 transition-all duration-200 hover:pl-4 ${
                                      activeDestKeyIndex ===
                                      i +
                                        1 +
                                        (destinations?.continents?.length || 0)
                                        ? "bg-gray-20 pl-4"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleDestSelection(des, "country")
                                    }
                                  >
                                    {des?.title?.en || des?.title?.de
                                      ? isEn
                                        ? des?.title?.en ?? des?.title?.de
                                        : des?.title?.de ?? des?.title?.en
                                      : des?.title}
                                  </li>
                                ))}

                              {/* states title */}
                              {destinations?.states?.length > 0 && (
                                <li className="text-base font-normal py-2 px-3 text-[#9ca3af]">
                                  {t("searchbar_category_states")}
                                </li>
                              )}

                              {/* states results */}
                              {destinations?.states?.length > 0 &&
                                destinations?.states?.map((des, i) => {
                                  const title =
                                    typeof des?.title === "object" &&
                                    !Array.isArray(des?.title)
                                      ? isEn
                                        ? des?.title?.en ||
                                          des?.title?.de ||
                                          des?.title
                                        : des?.title?.de ||
                                          des?.title?.en ||
                                          des?.title
                                      : des?.title;

                                  return (
                                    <li
                                      key={des?.alias + i}
                                      data-index={
                                        i +
                                        1 +
                                        (destinations?.continents?.length ||
                                          0) +
                                        (destinations?.countries?.length || 0)
                                      }
                                      ref={(el) =>
                                        (destItemsRefs.current[
                                          i +
                                            1 +
                                            (destinations?.continents?.length ||
                                              0) +
                                            (destinations?.countries?.length ||
                                              0)
                                        ] = el)
                                      }
                                      className={`text-base font-medium py-2 hover:bg-gray-20 px-3 transition-all duration-200 hover:pl-4 ${
                                        activeDestKeyIndex ===
                                        i +
                                          1 +
                                          (destinations?.continents?.length ||
                                            0) +
                                          (destinations?.countries?.length || 0)
                                          ? "bg-gray-20 pl-4"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleDestSelection(des, "state")
                                      }
                                    >
                                      {title}
                                    </li>
                                  );
                                })}
                            </div>
                          )}
                        </ul>
                      )}
                    </div>
                  )}
                </motion.div>
              )}
            </div>

            {/* date */}
            <div
              tabIndex="0"
              className="flex flex-col justify-center px-3 gap-0 cursor-pointer !outline-none"
              onClick={() => {
                setOpenDateBox((prev) => !prev);
                setOpenSport(false);
                setOpenDest(false);

                getDateData(destValue);
              }}
            >
              <p className="font-medium text-base">
                {t("frontpage.travel_date")}
              </p>
              {isMonthSelected &&
              !isDateSelected &&
              selectedMonth?.length > 0 ? (
                <div className="w-full flex items-center justify-between gap-1">
                  <p className="text-black text-base font-medium line-clamp-1">
                    {selectedMonth?.map(
                      (month, i) =>
                        `${i >= 1 ? " - " : ""} ${moment(month)
                          .locale(i18n.language)
                          .format("MMMM YYYY")}`
                    )}
                  </p>

                  <div>
                    <IoCloseCircleSharp
                      className="text-gray-70 hover:text-gray-500 text-base"
                      onClick={() => {
                        setOpenDateBox(true);
                        setIsMonthSelected(false);
                        setSelectedMonth([]);
                      }}
                    />
                  </div>
                </div>
              ) : !isMonthSelected && isDateSelected && startDate && endDate ? (
                <div className="w-full flex items-center justify-between gap-1">
                  <p className="text-black text-base font-medium">
                    {moment(startDate).format(dateFormat)} -{" "}
                    {moment(endDate).format(dateFormat)}
                  </p>
                  <div>
                    <IoCloseCircleSharp
                      className="text-gray-70 hover:text-gray-500 text-base"
                      onClick={() => {
                        setIsDateSelected(false);
                        setStartDate(null);
                        setEndDate(null);
                      }}
                    />
                  </div>
                </div>
              ) : (
                // default
                <div className="w-full flex items-center justify-between gap-2">
                  <p className="text-[#9ca3af] text-base line-clamp-1">
                    {t("frontpage.travel_date_ph")}
                  </p>
                  <IoIosArrowDown
                    className={`text-orange text-base transition-all duration-500 ${
                      openDateBox ? "rotate-180" : ""
                    }`}
                  />
                </div>
              )}
            </div>
          </div>

          {/* date box */}
          {openDateBox && (
            <motion.div
              className={`w-full absolute top-[82px] bottom-0 left-0 right-0 bg-white rounded-2xl shadow-xl overflow-hidden h-[450px] ${
                type === "home" ? "!z-[100]" : "!z-[120]"
              }`}
              initial={{ opacity: 0.1, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.1,
                type: "spring",
                stiffness: 300,
                damping: 50,
              }}
            >
              <div className="w-full h-full bg-white relative rounded-2xl px-4 py-2">
                <div className="w-full flex justify-center items-center">
                  <TailwindToggle
                    setValue={setIsFlexibale}
                    value={isFlexibale}
                  />
                </div>

                {!isCalendarLoading ? (
                  <div>
                    {!calendarData?.flexible?.full_access &&
                    calendarData?.flexible?.valid_month.length === 0 ? (
                      <motion.div
                        className="w-full h-full flex flex-col items-center justify-center mt-2"
                        initial={{ opacity: 0.1, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.1,
                          type: "spring",
                          stiffness: 300,
                          damping: 50,
                        }}
                      >
                        <Image
                          src="/icon/search/no-data.png"
                          alt="no data!"
                          preview={false}
                          width={"200px"}
                          height={"200px"}
                          loading="lazy"
                        />
                        <p className="max-w-[40%] text-center text-wrap !text-black font-medium">
                          {t("search.searchbar_no_data_text_dates")}
                        </p>
                      </motion.div>
                    ) : isFlexibale ? (
                      // Flexible - Month selection
                      <div className="w-full grid grid-cols-4 lg:grid-cols-4 gap-x-3 gap-y-4 px-5 mt-7 lg:mt-12">
                        {_.range(0, 12).map((monthNum) => {
                          const month = moment()
                            .add(monthNum, "M")
                            .format("YYYY-MM");

                          const isBlocked =
                            !calendarData?.flexible?.full_access &&
                            calendarData?.flexible?.valid_month?.length > 0 &&
                            !calendarData?.flexible?.valid_month?.some(
                              (mo) => moment(mo).format("YYYY-MM") === month
                            );

                          return (
                            <div
                              key={"month-item-" + monthNum}
                              className={`border border-gray-500 rounded-3xl w-full h-[80px] shadow-sm flex flex-col items-center justify-center hover:shadow-md transition-all duration-200 px-2 text-sm ${
                                isBlocked
                                  ? "bg-gray-70 !cursor-not-allowed"
                                  : "cursor-pointer bg-white hover:bg-gray-30"
                              } ${
                                selectedMonth?.includes(month)
                                  ? "!bg-[#fdd59e] shadow-lg"
                                  : ""
                              }`}
                              onClick={() => {
                                if (!isBlocked) {
                                  setIsMonthSelected(true);
                                  setIsDateSelected(false);

                                  if (selectedMonth?.includes(month)) {
                                    const filteredItems = selectedMonth?.filter(
                                      (m) => m !== month
                                    );
                                    setSelectedMonth(filteredItems);
                                  } else {
                                    setSelectedMonth([...selectedMonth, month]);
                                  }

                                  setStartDate(null);
                                  setEndDate(null);
                                }
                              }}
                            >
                              <p className="font-bold text-base">
                                {moment().add(monthNum, "M").format("MMMM")}
                              </p>
                              <p className="font-normal text-base">
                                {moment().add(monthNum, "M").format("YYYY")}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      // exact date
                      <div>
                        {!isFullAccess && validDates?.length === 0 ? (
                          <motion.div
                            className="w-full h-full flex flex-col items-center justify-center mt-2"
                            initial={{ opacity: 0.1, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                              duration: 0.1,
                              type: "spring",
                              stiffness: 300,
                              damping: 50,
                            }}
                          >
                            <Image
                              src="/icon/search/no-data.png"
                              alt="no data!"
                              preview={false}
                              width={"200px"}
                              height={"200px"}
                              loading="lazy"
                            />
                            <p className="max-w-[40%] text-center text-wrap !text-black font-medium">
                              {t("search.searchbar_no_data_text_dates")}
                            </p>
                          </motion.div>
                        ) : (
                          <ConfigProvider locale={isEn ? locale : deLocale}>
                            <div className="w-full flex justify-center">
                              <RangePicker
                                open={true}
                                format={DateHelper.getFormat()}
                                size="large"
                                value={[startDate, endDate]}
                                style={{ visibility: "hidden" }}
                                disabledDate={handleDisabledDates}
                                dropdownClassName="borderLess !border-none !z-[99999999]"
                                className="!border-none lg:!ml-[30%] !-mt-[100px]"
                                onChange={(e) => {
                                  setStartDate(e[0] || null);
                                  setEndDate(e[1] || null);

                                  setSelectedMonth([]);
                                  setIsMonthSelected(false);
                                  setIsDateSelected(e[0] && e[1]);
                                }}
                                bordered={false}
                                placement="bottomRight"
                                monthCellRender={(date) => date.format("MMMM")}
                                dateRender={(current) => (
                                  <div
                                    className="ant-calendar-date ant-picker-cell-inner"
                                    title={moment(current).format(dateFormat)}
                                  >
                                    {current.date()}
                                  </div>
                                )}
                                panelRender={(panel) => (
                                  <div className="w-full relative">
                                    <div className="w-full z-[9999]">
                                      {panel}
                                    </div>
                                    <span className="!absolute bottom-0 w-full h-[60px] !bg-white !z-[999999]" />
                                  </div>
                                )}
                                defaultPickerValue={
                                  !startDate &&
                                  !endDate &&
                                  !isFullAccess &&
                                  validDates?.length > 0 &&
                                  moment(
                                    validDates[0],
                                    "YYYY-MM-DD"
                                  ).isValid() &&
                                  moment(validDates[0], "YYYY-MM-DD").isAfter(
                                    moment(now)
                                  )
                                    ? [
                                        moment(validDates[0], "YYYY-MM-DD"),
                                        moment(validDates[0], "YYYY-MM-DD").add(
                                          1,
                                          "day"
                                        ),
                                      ]
                                    : null
                                }
                              />
                            </div>
                          </ConfigProvider>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="absolute top-[39%] left-[43%] !z-[99999] scale-110">
                      <div className="w-full he-full relative">
                        <div className="scale-95 orange_loading_container">
                          <div className="orange_loading_inner">
                            <div />
                            <div>
                              <div />
                            </div>
                          </div>
                        </div>
                        <div className="absolute top-[32px] left-[28%] scale-[1.4] blue_loading_container">
                          <div className="blue_loading_inner">
                            <div />
                            <div>
                              <div />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          )}

          <button
            className="bg-[#FF9F1C] rounded-full w-14 h-14 flex flex-col items-center justify-center absolute right-2 transition-all duration-700"
            onClick={applyFilters}
            aria-label={t("frontpage.search_mobile")}
          >
            <SearchIcon />
          </button>
        </div>
      </motion.div>

      {type === "home" && isSearchActive && (
        <div
          className="h-[700vh] top-0 bottom-0 right-0 left-0 w-[99.2vw] glass_shadow !z-[99] absolute"
          onClick={() => {
            setOpenSport(false);
            setOpenDest(false);
            setOpenDateBox(false);
            setIsSearchActive(false);
          }}
        />
      )}
    </div>
  );
};

export default DesktopSearchBar;
